// material-ui
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Link as MLink,
  Typography,
} from "@mui/material";
import useOnClickOutside from "hooks/useOnClickOutside";
import { useToggle } from "hooks/useToggle";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { headerHeight, headerHeightMobile } from "store/constant";
import "./Header.scss";
import { Path } from "routes/MainRoutes";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
  const [expandProduct, toggleExpandProduct, setExpandProduct] =
    useToggle(false);
  const [showMenu, toggleShowMenu] = useToggle(false);
  const mobileMenu = useRef(null);

  useOnClickOutside(mobileMenu, () => {
    toggleShowMenu();
    setExpandProduct(false);
  });
  return (
    <>
      {/* =========================================== logo & toggler Menu icon button ============================== */}
      <Box
        sx={{
          height: { xs: headerHeightMobile, lg: headerHeight },
          display: "flex",
          justifyContent: { xs: "space-between" },
          width: { xs: "100vw", lg: "fit-content" },
          paddingRight: { lg: "140px" },
          left: 0,
        }}
      >
        <Link to={Path.HomePage}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box
              component="img"
              sx={{
                maxHeight: { xs: 30, lg: "unset" },
              }}
              alt="horizontal menu gameconverge"
              src="assets/images/horizontal_menu.png"
            />
          </Box>
        </Link>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ display: { lg: "none" } }}
          onClick={toggleShowMenu}
        >
          <MenuIcon sx={{ color: "#CBCBCB" }} />
        </IconButton>
      </Box>

      {/* ======================================= normal menu  ====================== */}

      <Box
        sx={{
          display: { xs: "none", lg: "flex" },
          justifyContent: "space-between",
          width: "100%",
        }}
        component="div"
      >
        {/* ==================================== Left Menu ============================ */}
        <Box
          sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          <Link to={Path.HomePage}>
            <ListItemButton className="color-in">
              <ListItemText disableTypography primary={MenuItem("Home")} />
            </ListItemButton>
          </Link>

          <Box component="div" sx={{ position: "relative" }}>
            <Link to={Path.Product}>
              <ListItemButton className="color-in">
                <ListItemText disableTypography primary={MenuItem("Product")} />
                {/* {expandProduct ? <ExpandLess /> : <ExpandMore />} */}
              </ListItemButton>
            </Link>
          </Box>
        </Box>

        {/* ====================================== right menu  ============================= */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Divider orientation="vertical" sx={{ mx: 3 }} />
          <Box
            component="img"
            sx={{
              width: "28px",
              height: "28px",
              sr: 2,
              cursor: "pointer",
            }}
            alt="logo"
            src="assets/images/icon_phone.png"
          />
          <MLink
            href="mailto:https://business@gameconverge.org"
            target="_blank"
          >
            <Typography
              fontWeight="600"
              sx={{
                padding: "8px 16px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              business@gameconverge.org
            </Typography>
          </MLink>
        </Box>
      </Box>

      {/* ===================================== Mobile Menu  ============================= */}
      {showMenu && (
        <List
          ref={mobileMenu}
          className="mobile-header"
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <Box component="div" className="menu-top-content">
            <CloseIcon className="close-icon" onClick={toggleShowMenu} />
          </Box>
          <Box component="div" className="menu-content">
            <Link to={"/"}>
              <ListItemButton onClick={() => toggleShowMenu()}>
                <ListItemText disableTypography primary={MenuItem("Home")} />
              </ListItemButton>
            </Link>

            <ListItemButton
              selected={expandProduct}
              onClick={() => {
                // toggleShowMenu();
                toggleExpandProduct();
              }}
            >
              <ListItemText disableTypography primary={MenuItem("Product")} />

              {expandProduct ? (
                <ExpandLess sx={{ color: "#fff" }} />
              ) : (
                <ExpandMore sx={{ color: "#fff" }} />
              )}
            </ListItemButton>
            <Collapse in={expandProduct} timeout={0} unmountOnExit>
              <List component="div" disablePadding className="sub-menu">
                <Link to={Path.ProductACEWIN}>
                  <ListItemButton onClick={() => toggleShowMenu()}>
                    <ListItemText
                      disableTypography
                      primary={MenuItem("・ACEWIN", "500")}
                    />
                  </ListItemButton>
                </Link>

                <Link to={Path.ProductGCFB}>
                  <ListItemButton onClick={() => toggleShowMenu()}>
                    <ListItemText
                      disableTypography
                      primary={MenuItem("・GCFB", "500")}
                    />
                  </ListItemButton>
                </Link>

                <Link to={Path.ProductPG}>
                  <ListItemButton onClick={() => toggleShowMenu()}>
                    <ListItemText
                      disableTypography
                      primary={MenuItem("・PG", "500")}
                    />
                  </ListItemButton>
                </Link>

                <Link to={Path.ProductJDB}>
                  <ListItemButton onClick={() => toggleShowMenu()}>
                    <ListItemText
                      disableTypography
                      primary={MenuItem("・JDB", "500")}
                    />
                  </ListItemButton>
                </Link>

                <Link to={Path.ProductPLAY8}>
                  <ListItemButton onClick={() => toggleShowMenu()}>
                    <ListItemText
                      disableTypography
                      primary={MenuItem("・PLAY8", "500")}
                    />
                  </ListItemButton>
                </Link>

                <Link to={Path.ProductSEXYBCRT}>
                  <ListItemButton onClick={() => toggleShowMenu()}>
                    <ListItemText
                      disableTypography
                      primary={MenuItem("・SEXYBCRT", "500")}
                    />
                  </ListItemButton>
                </Link>

                <Link to={Path.ProductPP}>
                  <ListItemButton onClick={() => toggleShowMenu()}>
                    <ListItemText
                      disableTypography
                      primary={MenuItem("・PP", "500")}
                    />
                  </ListItemButton>
                </Link>

                <Link to={Path.ProductFC}>
                  <ListItemButton onClick={() => toggleShowMenu()}>
                    <ListItemText
                      disableTypography
                      primary={MenuItem("・FC", "500")}
                    />
                  </ListItemButton>
                </Link>
              </List>
            </Collapse>
          </Box>
        </List>
      )}
    </>
  );
};

const MenuItem = (title: string, fontWeight = "600") => (
  <Typography fontWeight={fontWeight} fontSize="16px">
    {title}
  </Typography>
);

export default Header;
