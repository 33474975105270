import { lazy } from 'react';
import MainLayout from '../containers/MainLayout/MainLayout';
import { RouteObject } from 'react-router-dom';
import Loadable from 'components/Loadable/Loadable';

// project imports
const HomePage = Loadable(lazy(() => import('containers/HomePage/HomePage')));
const Product = Loadable(lazy(() => import('containers/Product/Product')));
const ProductACEWINGaming = Loadable(lazy(() => import('containers/ProductICG/ProductACEWINGaming')));
const ProductGCFBGaming = Loadable(lazy(() => import('containers/ProductICG/ProductGCFBGaming')));
const ProductPGGaming = Loadable(lazy(() => import('containers/ProductICG/ProductPGGaming')));
const ProductJDBGaming = Loadable(lazy(() => import('containers/ProductICG/ProductJDBGaming')));
const ProductPLAY8Gaming = Loadable(lazy(() => import('containers/ProductICG/ProductPLAY8Gaming')));
const ProductSEXYBCRTGaming = Loadable(lazy(() => import('containers/ProductICG/ProductSEXYBCRTGaming')));
const ProductPPGaming = Loadable(lazy(() => import('containers/ProductICG/ProductPPGaming')));
const Page404 = Loadable(lazy(() => import('components/Error/Page404')));


export const Path = {
  HomePage: '/',
  Product: '/products',
  ProductACEWIN: '/products-acewin-gaming',
  ProductGCFB: '/products-gcfb-gaming',
  ProductPG: '/products-pg-gaming',
  ProductJDB: '/products-jdb-gaming',
  ProductPLAY8: '/products-play8-gaming',
  ProductSEXYBCRT: '/products-sexybcrt-gaming',
  ProductPP: '/products-pp-gaming',
  ProductFC: '',
}

// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes: RouteObject = {
  path: '',
  element: <MainLayout />,
  children: [
    {
      path: Path.HomePage,
      element: <HomePage />
    },
    {
      path: Path.Product,
      element: <Product />
    },
    {
      path: Path.ProductACEWIN,
      element: <ProductACEWINGaming />
    },
    {
      path: Path.ProductGCFB,
      element: <ProductGCFBGaming />
    },
    {
      path: Path.ProductPG,
      element: <ProductPGGaming />
    },
    {
      path: Path.ProductJDB,
      element: <ProductJDBGaming />
    },
    {
      path: Path.ProductPLAY8,
      element: <ProductPLAY8Gaming />
    },
    {
      path: Path.ProductSEXYBCRT,
      element: <ProductSEXYBCRTGaming />
    },
    {
      path: Path.ProductPP,
      element: <ProductPPGaming />
    },
    {
      path: '*',
      element: <Page404 />
    },
  ]
};

export default MainRoutes;
